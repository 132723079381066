
import Vue from "vue";
import VueDragscroll from "vue-dragscroll";
import movable from "v-movable";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import CharacterInfo from "@/components/atoms/CharacterInfo.vue";
import CorrelationArrows from "@/components/atoms/CorrelationArrows.vue";
import ButtonRound from "@/components/atoms/ButtonRound.vue";
import { getCharacterKey } from "@/lib/s3";
import clone from "lodash/cloneDeep";

Vue.use(VueDragscroll);
Vue.use(movable);

const ADJUSTMENT_COEFFICIENT: number = 3903.5;

export default Vue.extend({
  components: {
    VueSlider,
    CharacterInfo,
    CorrelationArrows,
    ButtonRound,
  },
  props: {
    novelId: String,
    diagram: Object,
    characterItems: Array as any,
    selectedCorrelationList: Array as any,
    disabledEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconSize: 170,
      fontSize: 24,
      showGridLine: false,
      magnification: 0.5,
      formatter: (value) => `${Math.round(value * 100)}%`,
    };
  },
  mounted() {
    /** 画面中央にスクロール */
    const elementWidth = this.$refs.scrollable.clientWidth;
    const elementHeight = this.$refs.scrollable.clientHeight;
    this.$refs.scrollable.scroll(
      4000 * this.magnification - elementWidth / 2,
      4000 * this.magnification - elementHeight / 2
    );
  },
  methods: {
    getCharacterData(characterId) {
      const { characterItems } = this;
      const character = clone(characterItems).find((item) => item.characterId === characterId);
      if (!character) {
        return null;
      }
      character.image = getCharacterKey(this.novelId, character);
      return character;
    },
    showSelectCorrelation() {
      this.$emit("clickedShowSelectCorrelation");
    },
    moveComplete(payload, characterId) {
      // ドラッグしないで要素をクリックした場合はreturn
      if (!payload.gridCss) {
        return;
      }

      const { top, left } = payload.gridCss; // 移動先の座標
      const { x, y } = payload.startCoord; // 移動前の座標

      this.$emit("move", {
        characterId,
        x: (left - ADJUSTMENT_COEFFICIENT) / 100,
        y: (top - ADJUSTMENT_COEFFICIENT) / 100,
      });

      /**
       * MEMO:
       *  座標のバリデーションに失敗した場合を考慮して初期位置に戻しておき、
       *  移動可能であればPropsの値が更新されて座標に反映される
       */
      this.$refs[characterId][0].top = y;
      this.$refs[characterId][0].left = x;
    },
  },
  computed: {
    /** データ座標系から描画座標系に変換（Ex. {x: 1, y: -1} -> {x: 4003.5, y: 3803.5}） */
    transformationCoordinate() {
      return (coordinate) => coordinate * 100 + ADJUSTMENT_COEFFICIENT;
    },
    calcDistance() {
      return (characterA, characterB) => {
        const { coordinate } = this.diagram;
        const coordinateA = coordinate.find((item) => item.characterId === characterA);
        const coordinateB = coordinate.find((item) => item.characterId === characterB);

        if (!coordinateA || !coordinateB) {
          return;
        }

        // 2点間の距離 = √((x2 - x1) ^ 2 + (y2 - y1) ^ 2)
        return Math.sqrt(
          (this.transformationCoordinate(coordinateA.x) - this.transformationCoordinate(coordinateB.x)) ** 2 +
            (this.transformationCoordinate(coordinateA.y) - this.transformationCoordinate(coordinateB.y)) ** 2
        );
      };
    },
    calcDegree() {
      return (characterA, characterB) => {
        const { coordinate } = this.diagram;
        const coordinateA = coordinate.find((item) => item.characterId === characterA);
        const coordinateB = coordinate.find((item) => item.characterId === characterB);

        if (!coordinateA || !coordinateB) {
          return;
        }

        // 2点間の角度 = arctan((y2 - y1) / (x2 - x1)) * 180 / π
        const radian = Math.atan2(
          this.transformationCoordinate(coordinateB.y) - this.transformationCoordinate(coordinateA.y),
          this.transformationCoordinate(coordinateB.x) - this.transformationCoordinate(coordinateA.x)
        );
        return radian * (180 / Math.PI);
      };
    },
    calcArrowsCoordinate() {
      return (characterA, characterB) => {
        const { coordinate } = this.diagram;
        const coordinateA = coordinate.find((item) => item.characterId === characterA);
        const coordinateB = coordinate.find((item) => item.characterId === characterB);

        if (!coordinateA || !coordinateB) {
          return;
        }

        const lineLength = this.calcDistance(characterA, characterB) - 215;
        const degree = this.calcDegree(characterA, characterB);
        const iconSizeIncludeMargin = this.iconSize + 22;
        const cernterCoordinate = {
          x:
            (this.transformationCoordinate(coordinateA.x) +
              iconSizeIncludeMargin / 2 +
              this.transformationCoordinate(coordinateB.x) +
              iconSizeIncludeMargin / 2) /
            2,
          y:
            (this.transformationCoordinate(coordinateA.y) +
              iconSizeIncludeMargin / 2 +
              this.transformationCoordinate(coordinateB.y) +
              iconSizeIncludeMargin / 2) /
            2,
        };

        // AとBの中間に配置
        return {
          top: `${cernterCoordinate.y - this.svgHeight(lineLength, degree) / 2}px`,
          left: `${cernterCoordinate.x - this.svgWidth(lineLength) / 2}px`,
        };
      };
    },
    svgWidth() {
      return (lineLength) => lineLength + 15;
    },
    svgHeight() {
      return (lineLength, degree) => {
        // tan(90)は存在しないのでsvgHeight = svgWidthとする
        if (Math.abs(degree) === 90) {
          return this.svgWidth(lineLength);
        }

        // 直角三角形の底辺(a)と角度(θ)から高さを求める = a * tan(θ)
        const radian = (degree * Math.PI) / 180;
        return Math.abs(this.svgWidth(lineLength) * Math.tan(radian)) + 70 + (this.fontSize - 12) * 2;
      };
    },
  },
  watch: {
    /** zoom倍率を変更した場合、画面中央を基点とするようにスクロールで調整 */
    magnification(after, before) {
      const difference = after - before;
      const scroll = difference * 4000;
      this.$refs.scrollable.scrollBy(scroll, scroll);
    },
  },
});
