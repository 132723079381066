
import Vue, { PropType } from "vue";
import { PlotGroups, Plots } from "@/lib/models/plot";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    plotGroups: {
      type: Array as PropType<PlotGroups[]>,
    },
    isEditing: {
      type: Boolean as PropType<boolean>,
    },
  },
  computed: {
    allPlots() {
      const { plotGroups } = this;
      return (plotGroups as PlotGroups[]).flatMap((plotGroup) => plotGroup.plots);
    },
  },
});

interface Props {
  plotGroups: PlotGroups[];
  isEditing: boolean;
}

interface Data {}

interface Computed {
  allPlots: Plots[];
}

interface Methods {}
