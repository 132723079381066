
import Vue, { PropType } from "vue";
import InputText from "@/components/atoms/InputText.vue";
import RadioIcon from "@/components/atoms/RadioIcon.vue";
import { Timeline, TimelineType, CreateTimelinePayload } from "@/lib/models/timeline";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { InputText, RadioIcon },
  props: {
    novelId: {
      type: String,
      required: true,
    },
    chronologyTimelines: {
      type: Array as PropType<Timeline[]>,
    },
    plotTimelines: {
      type: Array as PropType<Timeline[]>,
    },
    hasLimit: Boolean,
    negative: {
      type: String,
      default: "キャンセル",
    },
    positive: {
      type: String,
      default: "作成する",
    },
    negativeCallback: Function,
    positiveCallback: Function,
  },
  data() {
    const { hasLimit, chronologyTimelines } = this;
    const canSelectChronology = !hasLimit || chronologyTimelines.length === 0;

    return {
      timelineName: "",
      selectedTimelineType: canSelectChronology ? TimelineType.chronology : TimelineType.plot,
      checkBoxColor: "#707070",
    };
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      if (this.positiveCallback) {
        this.positiveCallback();
      }

      const { novelId, timelineName, selectedTimelineType } = this;
      const createTimelinePayload: CreateTimelinePayload = {
        novelId,
        timelineType: selectedTimelineType,
        name: timelineName,
        tableHeaders: selectedTimelineType === TimelineType.chronology ? [] : undefined,
        tableBodies: [],
        callback: (timelineId: string) => {
          this.$router.push({ name: "timeline", params: { novelId, timelineType: selectedTimelineType, timelineId } });
        },
      };
      this.$store.dispatch("timelineModule/createTimeline", createTimelinePayload);

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
    onChangeTimelineType(timelineType) {
      this.selectedTimelineType = timelineType;
    },
  },
  computed: {
    isCompleted() {
      const { timelineName, selectedTimelineType } = this;
      return !!timelineName && !!selectedTimelineType;
    },
    canSelectChronology() {
      const { hasLimit, chronologyTimelines } = this;
      return !hasLimit || chronologyTimelines.length === 0;
    },
    canSelectPlot() {
      const { hasLimit, plotTimelines } = this;
      return !hasLimit || plotTimelines.length === 0;
    },
  },
});

interface Props {
  novelId: string;
  chronologyTimelines: Timeline[];
  plotTimelines: Timeline[];
  hasLimit: boolean;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: () => void;
}

interface Data {
  timelineName: string;
  selectedTimelineType: TimelineType;
  checkBoxColor: string;
}

interface Methods {
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onClickOutSide: () => void;
  onChangeTimelineType(timelineType: TimelineType): void;
}

interface Computed {
  isCompleted: boolean;
  canSelectChronology: boolean;
  canSelectPlot: boolean;
}

export type TimelineCreateDialogProps = Props;
