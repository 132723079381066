
import Vue, { ComponentOptionsMixin } from "vue";
import { Bar, mixins } from "vue-chartjs";
// eslint-disable-next-line import/extensions
import { ThisTypedComponentOptionsWithRecordProps } from "vue/types/options";

type ChartType = "bar" | "line";

type DataSet = {
  type?: ChartType;
  data: number[];
  fill?: boolean;
  borderColor?: (context: any) => string;
  lineTension?: number;
};

export type ChartData = {
  labels: string[];
  datasets: DataSet[];
};

export type ChartOptions = {
  [key: string]: any;
};

interface PropsType {
  chartData: ChartData;
  options: ChartOptions;
}

type Mixin = ComponentOptionsMixin;
type Extends = ComponentOptionsMixin;

export function getGradient(ctx: any, chartArea: any) {
  let width;
  let height;
  let gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, "rgb(54, 162, 235)");
    gradient.addColorStop(0.5, "rgb(255, 205, 86)");
    gradient.addColorStop(1, "rgb(255, 99, 132)");
  }

  return gradient;
}

const { reactiveProp } = mixins;

export default Vue.extend({
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: () => ({
        labels: [],
        datasets: [],
      }),
    },
    options: {
      type: Object,
      default: () => ({
        legend: {},
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [],
          yAxes: [],
        },
      }),
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
} as ThisTypedComponentOptionsWithRecordProps<Vue, unknown, any, unknown, PropsType, unknown, Mixin, Extends>);
