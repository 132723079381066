
import Vue from "vue";

export default Vue.extend<{}, Methods, {}, Props>({
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      if (this.positiveCallback) {
        this.positiveCallback();
      }

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
  },
  props: {
    title: String,
    content: String,
    negative: {
      type: String,
      default: "キャンセル",
    },
    negativeCallback: Function,
    positive: {
      type: String,
      default: "はい",
    },
    positiveCallback: Function,
  },
});

interface Methods {
  onClickOutSide: () => void;
  onNegativeClick: () => void;
  onPositiveClick: () => void;
}

interface Props {
  title: string;
  content: string;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: () => void;
}

export type SimpleDialogProps = Props;
