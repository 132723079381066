
import Vue from "vue";
import CorrelationDiagram from "@/components/organisms/CorrelationDiagram.vue";
import { Correlation, CorrelationGroup, Diagram } from "@/lib/models/correlation";
import { Character } from "@/lib/models/character";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    CorrelationDiagram,
  },
  props: {
    novelId: String,
    correlationGroupId: String,
  },
  data() {
    return {
      enabledClose: false,
    };
  },
  computed: {
    correlationGroups() {
      return this.$store.getters["correlationModule/correlationGroupList"];
    },
    correlations() {
      return this.$store.getters["correlationModule/correlationList"];
    },
    characters() {
      return this.$store.getters["characterModule/characterList"];
    },
    diagram() {
      const { correlationGroups, correlationGroupId } = this;
      const currentCorrelationGroup = correlationGroups.find(
        (correlationGroup) => correlationGroup.correlationGroupId === correlationGroupId
      );

      if (!currentCorrelationGroup) return null;

      return currentCorrelationGroup.diagram || null;
    },
    selectedCorrelationList() {
      const { correlations, diagram } = this;

      if (!diagram) return [];

      const { correlationKeys } = diagram;
      return correlations.filter((item) => correlationKeys.find((key) => key === item.correlationId));
    },
    correlationGroupName() {
      const { correlationGroups, correlationGroupId } = this;
      const currentCorrelationGroup = correlationGroups.find(
        (correlationGroup) => correlationGroup.correlationGroupId === correlationGroupId
      );

      if (!currentCorrelationGroup) return "";

      return currentCorrelationGroup.name;
    },
  },
  methods: {
    onClickOutSide() {
      this.$close(true);
    },
    onMouseOverOutSide() {
      if (!this.enabledClose) return;

      this.$close(true);
    },
    onMouseOverContent() {
      this.enabledClose = true;
    },
  },
});

interface Props {
  novelId: string;
  correlationGroupId: string;
}

interface Data {
  enabledClose: boolean;
}

interface Computed {
  correlationGroups: CorrelationGroup[];
  correlations: Correlation[];
  characters: Character[];
  diagram: Diagram | null;
  selectedCorrelationList: Correlation[];
  correlationGroupName: string;
}

interface Methods {
  onClickOutSide(): void;
  onMouseOverOutSide(): void;
  onMouseOverContent(): void;
}

export type CorrelationDiagramDialogProps = Props;
