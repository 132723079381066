
import Vue from "vue";

export default Vue.extend<{}, {}, {}, Props>({
  props: {
    value: {
      required: true,
    },
    selected: {
      required: true,
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    fill: {
      type: String,
      default: "#d0d0d0",
    },
  },
});

interface Props {
  value: any;
  selected: any;
  iconSize: number;
  fill: string;
}
