import { Kind } from "./models/timeline";

export interface TimelineSelectBoxItem {
  id: string | Kind;
  name: string;
  image: string;
}
/** 時系列アイテム種別 */
export const timelineKinds: TimelineSelectBoxItem[] = [
  {
    id: Kind.story,
    name: "ストーリー",
    image: "none",
  },
  {
    id: Kind.event,
    name: "イベント",
    image: "none",
  },
  {
    id: Kind.foreshadowing,
    name: "伏線",
    image: "none",
  },
  {
    id: Kind.payoffForeshadowing,
    name: "伏線回収",
    image: "none",
  },
  {
    id: Kind.character,
    name: "登場人物",
    image: "none",
  },
  {
    id: Kind.material,
    name: "資料",
    image: "none",
  },
  {
    id: Kind.correlation,
    name: "相関図",
    image: "none",
  },
  {
    id: Kind.emotional,
    name: "感情",
    image: "none",
  },
  {
    id: Kind.volume,
    name: "巻数",
    image: "none",
  },
  {
    id: Kind.episode,
    name: "話数",
    image: "none",
  },
  {
    id: Kind.page,
    name: "ページ数",
    image: "none",
  },
  {
    id: Kind.title,
    name: "タイトル",
    image: "none",
  },
  {
    id: Kind.remarks,
    name: "備考",
    image: "none",
  },
];
