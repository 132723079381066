
import Vue, { PropType } from "vue";
import draggable, { MoveEvent } from "vuedraggable";
import { TableHeader, TimelineType } from "@/lib/models/timeline";
import { Dialog } from "@/lib/utils";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";
import InputText from "@/components/atoms/InputText.vue";
import { DeleteHeaderPayload, UpdateHeaderNamePayload } from "@/store/modules/timeline";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { draggable, InputText },
  props: {
    timelineType: {
      type: String as PropType<TimelineType>,
    },
    timelineId: {
      type: String as PropType<string>,
    },
    headers: {
      type: Array as PropType<TableHeader[]>,
    },
    isEditing: {
      type: Boolean as PropType<boolean>,
    },
  },
  data() {
    const { headers } = this;
    return {
      dataHeaders: headers,
      drag: false,
    };
  },
  methods: {
    onDragStart() {
      this.drag = true;
    },
    onDragEnd() {
      const { dataHeaders } = this;
      this.drag = false;
      this.$emit("change", dataHeaders);
    },
    onMoveHeader({ relatedContext, draggedContext }) {
      const { timelineType } = this;
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      return (
        relatedElement !== undefined &&
        draggedElement !== undefined &&
        relatedElement.id !== timelineType &&
        draggedElement.id !== timelineType
      );
    },
    onClickAddHeader() {
      const { timelineId } = this;
      this.$store.dispatch("timelineModule/addHeader", { timelineId });
    },
    onClickRemoveHeader(header) {
      const confirmDialog = new Dialog(SimpleDialog);
      const data: SimpleDialogProps = {
        title: "ヘッダの削除",
        content: "本当にヘッダを削除しますか？",
        positive: "削除する",
        positiveCallback: () => this.deleteHeader(header),
      };
      confirmDialog.show(data);
    },
    deleteHeader(header) {
      const { timelineId } = this;
      const deleteHeaderPayload: DeleteHeaderPayload = {
        timelineId,
        header,
      };
      this.$store.dispatch("timelineModule/deleteHeader", deleteHeaderPayload);
    },
    onChangeHeaderName(name, id) {
      const { timelineId } = this;
      const updateHeaderNamePayload: UpdateHeaderNamePayload = { timelineId, headerName: name, headerId: id };
      this.$store.dispatch("timelineModule/updateHeaderName", updateHeaderNamePayload);
    },
  },
  watch: {
    headers: {
      handler(headers: TableHeader[]) {
        this.dataHeaders = headers;
      },
      deep: true,
    },
  },
});

interface Props {
  timelineType: TimelineType;
  timelineId: string;
  headers: TableHeader[];
  isEditing: boolean;
}

interface Data {
  dataHeaders: TableHeader[];
  drag: boolean;
}

interface Computed {}

interface Methods {
  onDragStart(): void;
  onDragEnd(): void;
  onMoveHeader({ relatedContext, draggedContext }: MoveEvent<TableHeader | undefined>): void;
  onClickAddHeader(): void;
  onClickRemoveHeader(header: TableHeader): void;
  deleteHeader(header: TableHeader): void;
  onChangeHeaderName(name: string, id: string): void;
}
