
import Vue from "vue";
import ClickOutside from "vue-click-outside";
import { Color, ColorList } from "@/lib/colorPicker";

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: {
    ClickOutside,
  },
  model: {
    prop: "selected",
    event: "select",
  },
  props: {
    selected: String,
  },
  data() {
    return {
      colorList: ColorList,
      isSelecting: false,
    };
  },
  computed: {
    /** アプリのカラーコード(#AARRGGBB)をWebのカラーコード(#RRGGBB)に変換 */
    transformationColorCode() {
      return (colorCode) => {
        if (colorCode.length === 9) {
          return `#${colorCode.slice(3)}`;
        }
        return colorCode;
      };
    },
  },
  methods: {
    switchFragment() {
      this.isSelecting = !this.isSelecting;
    },
    close() {
      this.isSelecting = false;
    },
    selectColor(color) {
      this.$emit("select", color);
    },
  },
});

interface Props {
  selected: string;
}

interface Data {
  colorList: Color[];
  isSelecting: boolean;
}

interface Computed {
  transformationColorCode(colorCode: string): string;
}

interface Methods {
  switchFragment(): void;
  close(): void;
  selectColor(color: Color): void;
}
