
/* eslint-disable */
import Vue, { PropType } from "vue";
import draggable, { MoveEvent } from "vuedraggable";
import InputText from "@/components/atoms/InputText.vue";
import InputTextarea from "@/components/atoms/InputTextarea.vue";
import ImageViewRound from "@/components/atoms/ImageView.vue";
import ColorPicker from "@/components/molecules/ColorPicker.vue";
import { Dialog, isBilling } from "@/lib/utils";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";
import TimelineAddItemDialog, { TimelineAddItemDialogProps } from "@/components/ui/TimelineAddItemDialog.vue";
import CorrelationDiagramDialog, { CorrelationDiagramDialogProps } from "@/components/ui/CorrelationDiagramDialog.vue";
import { BodyItem, Kind, TableBody, TableHeader, TimelineType } from "@/lib/models/timeline";
import { Character } from "@/lib/models/character";
import { DeleteBodyPayload, UpdateBodyItemPayload } from "@/store/modules/timeline";
import { Color } from "@/lib/colorPicker";
import { Plan } from "@/lib/models/user";
import { NovelMaterial, LayoutEnum } from "@/lib/models/material";
import BarChart, { getGradient, ChartData, ChartOptions } from "@/components/molecules/charts/BarChart.vue";
import SelectBox, { ISelectBoxItem } from "@/components/molecules/SelectBox.vue";
import { CorrelationGroup } from "@/lib/models/correlation";

const textIcon = require("@/assets/img/icon/text_icon.png");

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    draggable,
    InputText,
    InputTextarea,
    ImageViewRound,
    ColorPicker,
    BarChart,
    SelectBox,
  },
  props: {
    novelId: {
      type: String as PropType<string>,
      required: true,
    },
    timelineType: {
      type: String as PropType<TimelineType>,
    },
    timelineId: {
      type: String as PropType<string>,
    },
    headers: {
      type: Array as PropType<TableHeader[]>,
    },
    bodies: {
      type: Array as PropType<TableBody[]>,
    },
    isEditing: {
      type: Boolean as PropType<boolean>,
    },
    isPlot: {
      type: Boolean as PropType<boolean>,
    },
  },
  data() {
    return {
      dataBodies: [],
      drag: false,
      iconPlaceholder: "/img/placeholders/character.png",
      timeoutId: null,
    };
  },
  computed: {
    characters() {
      return this.$store.getters["characterModule/characterList"];
    },
    material() {
      const { $store } = this;
      return (id) => $store.getters["materialModule/material"](id);
    },
    correlationGroups() {
      return this.$store.getters["correlationModule/correlationGroupList"];
    },
    plan() {
      return this.$store.getters["userModule/plan"];
    },
    findIndex() {
      return (array, searchElement) => array.findIndex((element) => element.key === searchElement);
    },
    isShowItemDetail() {
      return (kind) => kind === Kind.character || kind === Kind.material;
    },
    icon() {
      return (body) => {
        const { id, kind } = body;
        switch (kind) {
          case "character":
            const { novelId, characters } = this;
            const character = characters.find((x) => x.characterId === id);

            if (!character) return null;

            const { characterId, image } = character;
            if (image && image.startsWith("file:")) {
              return `file:novels/${novelId}/characters/${characterId}.jpg`;
            }
            return image;
          case "material":
            const { material } = this;
            const materialData = material(id);

            if (!materialData) return null;

            const { layout, items } = materialData;
            if (layout === LayoutEnum.DICTIONARY) return textIcon;
            return items[2].imagePath;
          default:
            return null;
        }
      };
    },
    label() {
      return (body) => {
        const { id, kind } = body;
        switch (kind) {
          case "character":
            const { characters } = this;
            const character = characters.find((x) => x.characterId === id);

            if (!character) return null;
            return character.name;
          case "material":
            const { material } = this;
            const materialData = material(id);

            if (!materialData) return null;
            return materialData.items[0].value!;
          default:
            return null;
        }
      };
    },
    kindName() {
      return (kind) => {
        switch (kind) {
          case "story":
            return "ストーリー";
          case "event":
            return "イベント";
          case "foreshadowing":
            return "伏線";
          case "payoffForeshadowing":
            return "伏線回収";
          case "character":
            return "登場人物";
          case "material":
            return "資料";
          case "correlation":
            return "相関図";
          case "emotional":
            return "感情";
          case "volume":
            return "巻数";
          case "episode":
            return "話数";
          case "page":
            return "ページ数";
          case "title":
            return "タイトル";
          case "remarks":
            return "備考";
        }
      };
    },
    inputType() {
      return (kind) => {
        switch (kind) {
          case "story":
          case "event":
          case "foreshadowing":
          case "payoffForeshadowing":
          case "character":
          case "material":
          case "title":
          case "remarks":
            return "textarea";
          case "volume":
          case "episode":
            return "text";
          case "page":
          case "emotional":
            return "number";
          case "correlation":
            return "selectbox";
        }
      };
    },
    bodyItemStyle() {
      return (body, header) => {
        const { isEditing, findIndex } = this;
        const bodyItemColor = body.items[findIndex(body.items, header.id)].color;
        return {
          backgroundColor: isEditing || bodyItemColor === Color.black ? "white" : bodyItemColor,
          height: body.kind === "emotional" && "150px",
        };
      };
    },
    isReachedLimit() {
      const { plan, dataBodies } = this;

      return plan === Plan.free && dataBodies.length >= 5;
    },
    isRectangle() {
      return (body) => {
        const { id, kind } = body;
        switch (kind) {
          case "material":
            const { material } = this;
            const materialData = material(id);

            if (!materialData) return false;
            return materialData.layout === LayoutEnum.DICTIONARY;
          default:
            return false;
        }
      };
    },
    chartData() {
      return (body) => {
        const { headers } = this;
        const labels = headers.map((header) => header.id);
        const sortedBodyItems = headers.map((header) => body.items.find((item) => item.key === header.id));
        const data = sortedBodyItems.map((item) => (item!.value ? Number(item!.value) : 0));

        return {
          labels,
          datasets: [
            {
              data,
              borderColor: (context: any) => {
                const { chart } = context;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  // This case happens on initial chart load
                  return;
                }
                return getGradient(ctx, chartArea);
              },
              fill: false,
              type: "line",
              lineTension: 0,
            },
          ],
        };
      };
    },
    chartOptions() {
      return {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
                drawTicks: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
                stepSize: 12.5,
                min: -12.5,
                max: 12.5,
              },
              gridLines: {
                drawTicks: false,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title() {
              return null;
            },
            label(tooltipsItems: any) {
              return `${tooltipsItems.yLabel}`;
            },
          },
        },
      };
    },
    correlationItems() {
      const { correlationGroups } = this;

      if (!correlationGroups.length) return [];

      return correlationGroups.map((correlationGroup) => {
        const { correlationGroupId, name } = correlationGroup;
        return {
          id: correlationGroupId,
          name,
          image: "none",
        } as ISelectBoxItem;
      });
    },
    selectedCorrelationItem() {
      return (bodyItem) => {
        const { correlationGroups } = this;
        const { value } = bodyItem;

        const selectedCorrelationGroup = correlationGroups.find(
          (correlationGroup) => correlationGroup.correlationGroupId === value
        );

        if (!selectedCorrelationGroup) return null;

        const { correlationGroupId, name } = selectedCorrelationGroup;
        return {
          id: correlationGroupId,
          name,
          image: "none",
        } as ISelectBoxItem;
      };
    },
    correlationName() {
      return (bodyItem) => {
        const { correlationGroups } = this;
        const { value } = bodyItem;

        const selectedCorrelationGroup = correlationGroups.find(
          (correlationGroup) => correlationGroup.correlationGroupId === value
        );

        if (!selectedCorrelationGroup) return "";

        const { name } = selectedCorrelationGroup;
        return name;
      };
    },
    availableCharacters() {
      const { bodies, characters } = this;
      const availableCharacters: Character[] = [];

      bodies.forEach((body) => {
        const { id, kind } = body;
        if (kind === Kind.character) {
          const availableCharacter = characters.find((x) => x.characterId === id);
          if (availableCharacter) availableCharacters.push(availableCharacter);
        }
      });

      return availableCharacters;
    },
    availableMaterials() {
      const { bodies, material } = this;
      const availableMaterials: NovelMaterial[] = [];

      bodies.forEach((body) => {
        const { id, kind } = body;
        if (kind === Kind.material) {
          const availableMaterial = material(id);
          if (availableMaterial) availableMaterials.push(availableMaterial);
        }
      });

      return availableMaterials;
    },
  },
  methods: {
    onDragStart() {
      this.drag = true;
    },
    onDragEnd() {
      const { dataBodies } = this;
      this.drag = false;
      this.$emit("change", dataBodies);
    },
    onMoveBody({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      return relatedElement !== undefined && draggedElement !== undefined;
    },
    async onClickAddItem() {
      const { $store, novelId, timelineId, headers, availableCharacters, availableMaterials } = this;

      const hasLimit = !(await isBilling($store));
      const selectedCharacters = availableCharacters;
      const selectedMaterials = availableMaterials;

      const timelineAddItemDialog = new Dialog(TimelineAddItemDialog);
      const data: TimelineAddItemDialogProps = {
        novelId,
        timelineId,
        headers,
        hasLimit,
        selectedCharacters,
        selectedMaterials,
      };
      timelineAddItemDialog.show(data);
    },
    onClickRemoveBody(body) {
      const confirmDialog = new Dialog(SimpleDialog);
      const data: SimpleDialogProps = {
        title: "アイテムの削除",
        content: "本当にアイテムを削除しますか？",
        positive: "削除する",
        positiveCallback: () => this.deleteBody(body),
      };
      confirmDialog.show(data);
    },
    deleteBody(body) {
      const { timelineId } = this;
      const deleteBodyPayload: DeleteBodyPayload = {
        timelineId,
        body,
      };
      this.$store.dispatch("timelineModule/deleteBody", deleteBodyPayload);
    },
    onChangeBodyItem(emitValue, bodyId, bodyItemId, isColor = false) {
      const { timelineId } = this;

      let value;
      switch (typeof emitValue) {
        case "string":
          value = emitValue;
          break;
        case "object":
          const { id } = emitValue as ISelectBoxItem;
          value = id;
          break;
        default:
          value = null;
      }

      const updateBodyItemPayload: UpdateBodyItemPayload = {
        timelineId,
        value,
        bodyId,
        bodyItemId,
        isColor,
      };
      this.$store.dispatch("timelineModule/updateBodyItem", updateBodyItemPayload);
    },
    showCorrelationDiagramDialog(correlationGroupId) {
      const { novelId } = this;
      const correlationDiagramDialog = new Dialog(CorrelationDiagramDialog);
      const data: CorrelationDiagramDialogProps = {
        novelId,
        correlationGroupId,
      };
      correlationDiagramDialog.show(data);
    },
    onClickCorrelationName(correlationGroupId) {
      this.showCorrelationDiagramDialog(correlationGroupId);
    },
    onMouseOverCorrelationName(correlationGroupId) {
      const timeoutId = setTimeout(() => {
        this.showCorrelationDiagramDialog(correlationGroupId);
      }, 500);
      this.timeoutId = timeoutId;
    },
    onMouseLeaveCorrelationName() {
      const { timeoutId } = this;
      if (timeoutId) clearTimeout(timeoutId);
      this.timeoutId = null;
    },
    updateDataBodies() {
      const { bodies } = this;
      const availableBodies = bodies.filter((body) => {
        const { id, kind } = body;
        switch (kind as Kind) {
          case Kind.character:
            const { characters } = this;
            const availableCharacter = characters.some((x) => x.characterId === id);

            if (availableCharacter) return true;
            return false;
          case Kind.material:
            const { material } = this;
            const availableMaterial = material(id);

            if (availableMaterial) return true;
            return false;
          default:
            return true;
        }
      });
      this.dataBodies = availableBodies;
    },
  },
  watch: {
    bodies: {
      handler() {
        const { updateDataBodies } = this;
        updateDataBodies();
      },
      deep: true,
      immediate: true,
    },
  },
});

interface Props {
  novelId: string;
  timelineType: TimelineType;
  timelineId: string;
  headers: TableHeader[];
  bodies: TableBody[];
  isEditing: boolean;
  isPlot: boolean;
}

interface Data {
  dataBodies: TableBody[];
  drag: boolean;
  iconPlaceholder: string;
  timeoutId: NodeJS.Timer | null;
}

interface Computed {
  characters: Character[];
  material: (id: string) => NovelMaterial | undefined;
  correlationGroups: CorrelationGroup[];
  plan: Plan;
  findIndex(array: BodyItem[], searchElement: string): number;
  isShowItemDetail(kind: Kind): boolean;
  icon(body: TableBody): string | null;
  label(body: TableBody): string | null;
  kindName(kind: Kind): string;
  inputType(kind: Kind): string;
  bodyItemStyle(body: TableBody, header: TableHeader): object;
  isReachedLimit: boolean;
  isRectangle(body: TableBody): boolean;
  chartData(body: TableBody): ChartData;
  chartOptions: ChartOptions;
  correlationItems: ISelectBoxItem[];
  selectedCorrelationItem(bodyItem: BodyItem): ISelectBoxItem | null;
  correlationName(bodyItem: BodyItem): string;
  availableCharacters: Character[];
  availableMaterials: NovelMaterial[];
}

interface Methods {
  onDragStart(): void;
  onDragEnd(): void;
  onMoveBody({ relatedContext, draggedContext }: MoveEvent<TableBody | undefined>): void;
  onClickAddItem(): void;
  onClickRemoveBody(header: TableBody): void;
  deleteBody(header: TableBody): void;
  onChangeBodyItem(
    emitValue: string | Color | ISelectBoxItem,
    bodyId: string,
    bodyItemId: string,
    isColor: boolean
  ): void;
  showCorrelationDiagramDialog(value: string): void;
  onClickCorrelationName(value: string): void;
  onMouseOverCorrelationName(value: string): void;
  onMouseLeaveCorrelationName(): void;
  updateDataBodies(): void;
}
