
import Vue from "vue";
import CheckboxIcon from "@/components/atoms/CheckboxIcon.vue";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";
import { Timeline, DeleteTimelinePayload } from "@/lib/models/timeline";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CheckboxIcon, MaskedLoading },
  props: {
    novelId: {
      type: String,
      required: true,
    },
    chronologyTimelines: Array,
    plotTimelines: Array,
    currentTimelineId: String,
    negative: {
      type: String,
      default: "キャンセル",
    },
    positive: {
      type: String,
      default: "削除する",
    },
    negativeCallback: Function,
    positiveCallback: Function,
  },
  data() {
    return {
      selectedTimelines: [],
      isDeleting: false,
    };
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    async onPositiveClick() {
      if (this.positiveCallback) {
        this.positiveCallback();
      }

      this.isDeleting = true;

      const { novelId, currentTimelineId, selectedTimelines } = this;

      const promiseArray = selectedTimelines.map(async (timeline) => {
        const deleteTimelinePayload: DeleteTimelinePayload = {
          novelId,
          timelineId: timeline.timelineId,
          callback: () => {
            if (timeline.timelineId === currentTimelineId) {
              this.$router.push({ name: "timeline", params: { novelId } });
            }
          },
        };
        await this.$store.dispatch("timelineModule/deleteTimeline", deleteTimelinePayload);
      });
      await Promise.all(promiseArray);

      this.isDeleting = false;

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
    onClickTimeline(timeline) {
      const { selectedTimelines } = this;
      if (selectedTimelines.some((x) => x.timelineId === timeline.timelineId)) {
        this.selectedTimelines = selectedTimelines.filter((x) => x.timelineId !== timeline.timelineId);
      } else {
        this.selectedTimelines.push(timeline);
      }
    },
  },
  computed: {
    isChecked() {
      return (timeline) => {
        const { selectedTimelines } = this;
        return selectedTimelines.some((x) => x.timelineId === timeline.timelineId);
      };
    },
    isCompleted() {
      const { selectedTimelines } = this;
      return selectedTimelines.length > 0;
    },
  },
});

interface Props {
  novelId: string;
  chronologyTimelines: Timeline[];
  plotTimelines: Timeline[];
  currentTimelineId: string;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: () => void;
}

interface Data {
  selectedTimelines: Timeline[];
  isDeleting: boolean;
}

interface Methods {
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onClickOutSide: () => void;
  onClickTimeline(timeline: Timeline): void;
}

interface Computed {
  isChecked(timeline: Timeline): boolean;
  isCompleted: boolean;
}

export type TimelineDeleteDialogProps = Props;
