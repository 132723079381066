
import Vue, { PropType } from "vue";
import InputText from "@/components/atoms/InputText.vue";
import { Timeline, UpdateTimelinePayload } from "@/lib/models/timeline";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { InputText },
  props: {
    timeline: {
      type: Object as PropType<Timeline>,
    },
    negative: {
      type: String,
      default: "キャンセル",
    },
    positive: {
      type: String,
      default: "保存する",
    },
    negativeCallback: Function,
    positiveCallback: Function,
  },
  data() {
    const { name } = this.timeline;
    return {
      name,
      checkBoxColor: "#707070",
    };
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      if (this.positiveCallback) {
        this.positiveCallback();
      }

      const { novelId, timelineId, timelineType, tableHeaders, tableBodies } = this.timeline;
      const { name } = this;

      const updateTimelinePayload: UpdateTimelinePayload = {
        novelId,
        timelineId,
        timelineType,
        name,
        tableHeaders,
        tableBodies,
      };
      this.$store.dispatch("timelineModule/updateTimeline", updateTimelinePayload);

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
  },
  computed: {
    isCompleted() {
      const { name } = this;
      return !!name;
    },
  },
});

interface Props {
  timeline: Timeline;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: () => void;
}

interface Data {
  name: string;
  checkBoxColor: string;
}

interface Methods {
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onClickOutSide: () => void;
}

interface Computed {
  isCompleted: boolean;
}

export type TimelineEditTitleDialogProps = Props;
