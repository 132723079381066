
import Vue from "vue";
import { API, Storage } from "aws-amplify";
import { setTimeout } from "timers";
import { S3Client } from "@/lib/clients";
import { NolaApiInitializer, NolaNovelApiInitializer } from "@/initializer";

interface Data {
  imagePath: string | undefined;
  loaded: boolean;
}

interface Method {
  loadImageFile: () => void;
}

interface Props {
  src: string;
  placeholder: string;
}

export default Vue.extend<Data, Method, unknown, Props>({
  props: {
    src: String,
    placeholder: {
      type: String,
      default: "/img/placeholders/novel.png",
    },
  },
  data() {
    return {
      imagePath: undefined,
      loaded: false,
    };
  },
  async created() {
    this.loadImageFile();
  },
  methods: {
    async loadImageFile() {
      const { src, placeholder } = this;

      let imagePath: string | null = null;

      this.imagePath = placeholder;

      if (!src) {
        this.loaded = true;
        return;
      }

      const isPreset = src.startsWith("preset:");
      const isShared = src.startsWith("shared");
      const isFile = src.startsWith("file:");
      const isPath = src.startsWith("novels/");
      const isNolaNovel = src.startsWith("nolaNovel:");

      if (!isPreset && !isShared && !isFile && !isPath && !isNolaNovel) {
        this.loaded = true;
        return;
      }

      NolaNovelApiInitializer.init();
      if (isNolaNovel) {
        const res = (await Storage.get(src.substring("nolaNovel:".length), {
          level: "public",
        })) as string;
        imagePath = res;
      }

      NolaApiInitializer.init();

      if (isPreset) {
        const res: { url: string } = await API.get("Rest", `/s3/public/${src.substring("preset:".length)}`, {});
        imagePath = res.url;
      }

      if (isShared) {
        const res: { url: string } = await API.get("Rest", `/s3/public/${src}`, {});
        imagePath = res.url;
      }

      if (isFile) {
        const raw = src.substring("file:".length);
        const res = await Storage.vault.get(raw, {
          download: false,
        });
        imagePath = res as string;
        this.loaded = true;
      }

      if (isPath) {
        imagePath = await new S3Client().getImagePath(src);
        this.loaded = true;
      }

      this.imagePath = imagePath ?? undefined;
      setTimeout(() => {
        this.loaded = true;
      }, 300);
    },
  },
  watch: {
    src() {
      // srcが変更されたらもう一度、画像をロードする
      this.loadImageFile();
    },
  },
});
