
import Vue from "vue";
import ImageViewRound from "@/components/atoms/ImageView.vue";

export default Vue.extend({
  components: { ImageViewRound },
  props: {
    character: Object,
    iconSize: Number,
    nameSize: String,
  },
  data() {
    return {
      icon: this.character ? this.character.image : null,
      name: this.character ? this.character.name : null,
      iconSizeVal: this.iconSize || 70,
      nameSizeVal: this.nameSize || "unset",
    };
  },
  watch: {
    character() {
      if (this.character) {
        this.icon = this.character.image;
        this.name = this.character.name;
      }
    },
  },
});
