
import Vue, { PropType } from "vue";
import { Timeline, TimelineType } from "@/lib/models/timeline";
import PlusCircleIcon from "icons/PlusCircle.vue";
import DeleteIcon from "icons/Delete.vue";
import { Dialog, isBilling } from "@/lib/utils";
import TimelineCreateDialog, { TimelineCreateDialogProps } from "@/components/ui/TimelineCreateDialog.vue";
import TimelineDeleteDialog, { TimelineDeleteDialogProps } from "@/components/ui/TimelineDeleteDialog.vue";
import TimelineEditTitleDialog, { TimelineEditTitleDialogProps } from "@/components/ui//TimelineEditTitleDialog.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { PlusCircleIcon, DeleteIcon },
  props: {
    novelId: {
      type: String as PropType<string>,
    },
    timelineType: {
      type: String as PropType<TimelineType>,
    },
    timelineId: {
      type: String as PropType<string>,
    },
    timelines: {
      type: Array as PropType<Timeline[]>,
    },
    currentTimeline: {
      type: Object as PropType<Timeline>,
    },
    isEditing: {
      type: Boolean as PropType<boolean>,
    },
  },
  data() {
    const { timelineId } = this;
    return {
      selectedTimelineId: timelineId,
    };
  },
  computed: {
    chronologyTimelines() {
      const { timelines } = this;
      return timelines.filter((timeline) => timeline.timelineType === TimelineType.chronology);
    },
    plotTimelines() {
      const { timelines } = this;
      return timelines.filter((timeline) => timeline.timelineType === TimelineType.plot);
    },
  },
  methods: {
    async onClickAdd() {
      const { $store, $router, novelId, chronologyTimelines, plotTimelines } = this;

      const hasLimit = !(await isBilling($store));
      const pushAnnounce = hasLimit && chronologyTimelines.length && plotTimelines.length;
      if (pushAnnounce) {
        $router.push({ name: "subscriptionAnnounce", query: { from: "addTimeline" } });
        return;
      }

      const timelineCreateDialog = new Dialog(TimelineCreateDialog);
      const data: TimelineCreateDialogProps = { novelId, chronologyTimelines, plotTimelines, hasLimit };
      timelineCreateDialog.show(data);
    },
    onClickDelete() {
      const { novelId, chronologyTimelines, plotTimelines, timelineId } = this;
      const timelineDeleteDialog = new Dialog(TimelineDeleteDialog);
      const data: TimelineDeleteDialogProps = {
        novelId,
        chronologyTimelines,
        plotTimelines,
        currentTimelineId: timelineId!,
      };
      timelineDeleteDialog.show(data);
    },
    onClickEditTitle() {
      const { currentTimeline } = this;
      const timelineEditTitleDialog = new Dialog(TimelineEditTitleDialog);
      const data: TimelineEditTitleDialogProps = { timeline: currentTimeline };
      timelineEditTitleDialog.show(data);
    },
    onChangeTimeline(timelineId) {
      const { timelines, novelId } = this;

      const selectedTimeline = timelines.find((timeline) => timeline.timelineId === timelineId);
      if (!selectedTimeline) return;

      const { timelineType } = selectedTimeline;
      this.$router.push({ name: "timeline", params: { novelId, timelineType, timelineId } });
    },
  },
  watch: {
    timelineId: {
      handler(timelineId: string) {
        this.selectedTimelineId = timelineId;
      },
    },
  },
});

interface Props {
  novelId: string;
  timelineType?: TimelineType;
  timelineId?: string;
  timelines: Timeline[];
  currentTimeline: Timeline;
  isEditing: boolean;
}

interface Data {
  selectedTimelineId: string | undefined;
}

interface Computed {
  chronologyTimelines: Timeline[];
  plotTimelines: Timeline[];
}

interface Methods {
  onClickAdd(): void;
  onClickDelete(): void;
  onClickEditTitle(): void;
  onChangeTimeline(timelineId: string): void;
}
