
import Vue, { PropType } from "vue";

const ClickOutside = require("vue-click-outside");

export interface ISelectBoxItem {
  id: string;
  name: string;
  image: string;
  imageCropType?: TImageCropType;
  disabled?: boolean;
}

export const ImageCropType = {
  /** 円形 */
  round: "round",
  /** 四角形 */
  rectangle: "rectangle",
} as const;
export type TImageCropType = typeof ImageCropType[keyof typeof ImageCropType];

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: { ClickOutside },
  props: {
    items: {
      type: Array as PropType<ISelectBoxItem[]>,
      required: true,
    },
    keyAttr: {
      type: String as PropType<string>,
      required: true,
    },
    placeholder: {
      type: String as PropType<string>,
      required: false,
    },
    iconPlaceholder: {
      type: String as PropType<string>,
      required: false,
    },
    selected: Object as PropType<ISelectBoxItem>,
    filterMode: Boolean as PropType<boolean>,
    enableUnselected: Boolean as PropType<boolean>,
    grayColor: Boolean as PropType<boolean>,
    disabled: Boolean as PropType<boolean>,
  },
  data() {
    return {
      dataItems: this.$props.items,
      isSelecting: false,
      selectBoxItemComponent: null as any,
    };
  },
  created() {
    // ブラウザでタブ複製時にキャッシュが影響して正しくコンポーネントがロードされないため、
    // ダイナミックインポートを使用してコンポーネントをロード
    this.selectBoxItemComponent = () => import("@/components/molecules/SelectBoxItem.vue");
  },
  watch: {
    items() {
      this.dataItems = this.items;
    },
  },
  methods: {
    switchFragment() {
      this.isSelecting = !this.isSelecting;
    },
    close() {
      this.isSelecting = false;
    },
    selectItem(item) {
      this.$emit("select", item);
    },
    unselect() {
      this.$emit("unselect");
    },
  },
});

interface Props {
  items: ISelectBoxItem[];
  keyAttr: string;
  placeholder: string;
  iconPlaceholder: string;
  selected: ISelectBoxItem;
  filterMode: boolean;
  enableUnselected: boolean;
  grayColor: boolean;
  disabled: boolean;
}

interface Data {
  dataItems: ISelectBoxItem[];
  isSelecting: boolean;
  selectBoxItemComponent: any;
}

interface Methods {
  switchFragment(): void;
  close(): void;
  selectItem(item: ISelectBoxItem): void;
  unselect(): void;
}

interface Computed {}
