
import Vue, { PropType } from "vue";
import SelectBox, { ISelectBoxItem, ImageCropType } from "@/components/molecules/SelectBox.vue";
import { timelineKinds, TimelineSelectBoxItem } from "@/lib/timeline";
import { Character } from "@/lib/models/character";
import { Kind, TableHeader } from "@/lib/models/timeline";
import { NovelMaterial, LayoutEnum, MaterialAttribute } from "@/lib/models/material";

const textIcon = require("@/assets/img/icon/text_icon.png");

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { SelectBox },
  props: {
    novelId: {
      type: String,
      required: true,
    },
    timelineId: String,
    headers: Array as PropType<TableHeader[]>,
    selectedCharacters: Array as PropType<Character[]>,
    selectedMaterials: Array as PropType<NovelMaterial[]>,
    hasLimit: Boolean,
    negative: {
      type: String,
      default: "キャンセル",
    },
    positive: {
      type: String,
      default: "選択する",
    },
    negativeCallback: Function,
    positiveCallback: Function,
  },
  data() {
    return {
      kind: null,
      item: null,
      timelineKinds,
      timelineItems: [],
    };
  },
  methods: {
    onNegativeClick() {
      if (this.negativeCallback) {
        this.negativeCallback();
      }

      this.$close(true);
    },
    onPositiveClick() {
      if (this.positiveCallback) {
        this.positiveCallback();
      }

      const { timelineId, kind, item, headers } = this;
      this.$store.dispatch("timelineModule/addBody", { timelineId, kind, item, headers });

      this.$close(true);
    },
    onClickOutSide() {
      this.$close(true);
    },
    selectKind(item) {
      this.kind = item;
    },
    unselectKind() {
      this.kind = null;
    },
    selectItem(item) {
      this.item = item;
    },
    unselectItem() {
      this.item = null;
    },
    onClickLink() {
      this.$close(true);
    },
  },
  computed: {
    characters() {
      return this.$store.getters["characterModule/characterList"];
    },
    materials() {
      const { $store } = this;
      return (attributeId) => $store.getters["materialModule/materialsFromAttribute"](attributeId);
    },
    attributes() {
      const { novelId, $store } = this;
      return $store.getters["materialModule/attributesFromNovelId"](novelId, true);
    },
    needSelectItem() {
      const { kind, characters, materials, attributes, selectedCharacters, selectedMaterials } = this;

      if (!kind) return false;

      const { id } = kind as TimelineSelectBoxItem;
      switch (id) {
        case Kind.character:
          // eslint-disable-next-line no-case-declarations
          const selectboxCharacters: ISelectBoxItem[] = characters.map((character) => {
            const { novelId, characterId, name } = character;
            let { image } = character;
            if (image && image.startsWith("file:")) {
              image = `file:novels/${novelId}/characters/${characterId}.jpg`;
            }

            return {
              id: characterId,
              name,
              image,
            } as ISelectBoxItem;
          });

          // eslint-disable-next-line no-case-declarations
          const filterd = selectboxCharacters.filter(
            (x) => !selectedCharacters.some((character) => character.characterId === x.id)
          );
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.timelineItems = filterd;
          return true;
        case Kind.material:
          // eslint-disable-next-line no-case-declarations
          const selectboxMaterialsWithAttribute: ISelectBoxItem[] = [];
          attributes.forEach((attribute) => {
            const materialsInAttribute = materials(attribute.id);
            if (materialsInAttribute) {
              const attributeNameItem: ISelectBoxItem = {
                id: attribute.id,
                name: attribute.name,
                image: "none",
                disabled: true,
              };
              selectboxMaterialsWithAttribute.push(attributeNameItem);

              const selectboxMaterials: ISelectBoxItem[] = materialsInAttribute.map((material) => {
                const { id, layout, items } = material;
                const name = items[0].value;

                let image;
                let imageCropType;
                switch (layout) {
                  case LayoutEnum.DICTIONARY:
                    image = textIcon;
                    imageCropType = ImageCropType.rectangle;
                    break;
                  default:
                    image = items[2].imagePath;
                    imageCropType = ImageCropType.round;
                    break;
                }

                return {
                  id,
                  name,
                  image,
                  imageCropType,
                } as ISelectBoxItem;
              });

              const filterd = selectboxMaterials.filter(
                (x) => !selectedMaterials.some((material) => material.id === x.id)
              );
              selectboxMaterialsWithAttribute.push(...filterd);
            }
          });
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.timelineItems = selectboxMaterialsWithAttribute;
          return true;
        default:
          return false;
      }
    },
    isCompleted() {
      const { kind, item, needSelectItem } = this;

      if (!kind) return false;

      return !needSelectItem || !!item;
    },
    isShowCaution() {
      const { hasLimit, kind } = this;

      return hasLimit && kind ? kind.id === Kind.material : false;
    },
  },
});

interface Props {
  novelId: string;
  timelineId: string;
  headers: TableHeader[];
  selectedCharacters: Character[];
  selectedMaterials: NovelMaterial[];
  hasLimit: boolean;
  negative?: string;
  positive?: string;
  negativeCallback?: () => void;
  positiveCallback?: () => void;
}

interface Data {
  kind: TimelineSelectBoxItem | null;
  item: TimelineSelectBoxItem | null;
  timelineKinds: TimelineSelectBoxItem[];
  timelineItems: TimelineSelectBoxItem[];
}

interface Methods {
  onNegativeClick: () => void;
  onPositiveClick: () => void;
  onClickOutSide: () => void;
  selectKind: (item: TimelineSelectBoxItem) => void;
  unselectKind: () => void;
  selectItem: (item: TimelineSelectBoxItem) => void;
  unselectItem: () => void;
  onClickLink: () => void;
}

interface Computed {
  characters: Character[];
  materials: (attributeId: string) => NovelMaterial[];
  attributes: MaterialAttribute[];
  needSelectItem: boolean;
  isCompleted: boolean;
  isShowCaution: boolean;
}

export type TimelineAddItemDialogProps = Props;
