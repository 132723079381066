
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";

export default Vue.extend({
  props: {
    showPlaceholder: Boolean,
    relationshipAtoB: Object,
    relationshipBtoA: Object,
    distance: Number, // 左上を基点としたAとBの距離
    degree: Number, // 水平を基準とした角度
    fontSize: Number,
  },
  data() {
    return {
      relAtoB: {
        id: uuidv4(),
        relationship: this.relationshipAtoB.relationship,
        color: this.relationshipAtoB.color,
      },
      relBtoA: {
        id: uuidv4(),
        relationship: this.relationshipBtoA.relationship,
        color: this.relationshipBtoA.color,
      },
      lineLength: this.distance ? this.distance - 215 : 85,
      fontSizeVal: this.fontSize || 12,
    };
  },
  methods: {
    /** markerを指定するための一意なidを付与する */
    addId(object) {
      return {
        id: uuidv4(),
        relationship: object.relationship,
        color: this.transformationColorCode(object.color),
      };
    },
    ellipsisText(text) {
      if (text) {
        // 文字列の横幅を計算するためのspanを作成
        const span = document.createElement("span");
        span.style.position = "absolute";
        span.style.top = "-1000px";
        span.style.left = "-1000px";
        span.style.whiteSpace = "nowrap";
        span.style.fontSize = `${this.fontSizeVal}px`;
        span.innerHTML = text;
        document.body.appendChild(span);
        const width = span.clientWidth;

        // svgの幅よりも大きい場合は文字を省略
        if (width > this.svgWidth) {
          const averageCharLength = width / text.length;
          const availableCharLength = Math.floor(this.svgWidth / averageCharLength);
          // eslint-disable-next-line no-param-reassign
          text = `${text.slice(0, availableCharLength - 2)}…`;
        }

        // spanを削除
        document.body.removeChild(span);
      }
      return text;
    },
  },
  computed: {
    svgWidth() {
      const { lineLength } = this;
      return lineLength + 15;
    },
    svgHeight() {
      const { degree } = this;

      // tan(90)は存在しないのでsvgHeight = svgWidthとする
      if (Math.abs(degree) === 90) {
        return this.svgWidth;
      }

      let height = 0;
      if (degree) {
        // 直角三角形の底辺(a)と角度(θ)から高さを求める = a * tan(θ)
        const radian = (degree * Math.PI) / 180;
        height = Math.abs(this.svgWidth * Math.tan(radian));
      }
      return height + 70 + (this.fontSizeVal - 12) * 2;
    },
    /** アプリのカラーコード(#AARRGGBB)をWebのカラーコード(#RRGGBB)に変換 */
    transformationColorCode() {
      return (colorCode: string) => {
        if (colorCode.length === 9) {
          return `#${colorCode.slice(3)}`;
        }
        return colorCode;
      };
    },
  },
  watch: {
    relationshipAtoB: {
      handler() {
        this.relAtoB = this.addId(this.relationshipAtoB);
      },
      deep: true,
      immediate: true,
    },
    relationshipBtoA: {
      handler() {
        this.relBtoA = this.addId(this.relationshipBtoA);
      },
      deep: true,
      immediate: true,
    },
    distance() {
      this.lineLength = this.distance ? this.distance - 215 : 85;
    },
  },
});
